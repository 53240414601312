import "../styles/globals.css";

import type { Session } from "next-auth";
import type { AppType } from "next/app";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import localFont from "next/font/local";
import Head from "next/head";
import { useRouter } from "next/router";
import SentryUserManager from "@components/utils/SentryUserManager";
import i18nConfig from "@i18nConfig";
import { SessionProvider, useSession } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Toaster } from "react-hot-toast";

import { isDev } from "@gility/lib";
import { isServer } from "@gility/lib/browser/browser.utils";
import { pageview } from "@gility/lib/browser/gtm";
import { GTM_ID, POSTHOG_API_KEY, SENTRY_DSN as sentryEnabled } from "@gility/lib/constants";
import { Didomi, GTM_Global, TooltipProvider } from "@gility/ui";

import { api } from "~/utils/api";

const acumin = localFont({
  src: "../../fonts/Acumin.woff2",
  variable: "--font-acumin",
  preload: true,
  display: "swap",
});

// Check that PostHog is client-side (used to handle Next.js SSR)
if (!isServer && POSTHOG_API_KEY) {
  posthog.init(POSTHOG_API_KEY, {
    ui_host: "https://eu.i.posthog.com",
    api_host: "/ingest",
    person_profiles: "identified_only",
    capture_pageleave: true,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (isDev) posthog.debug();
    },
  });
}

const App: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <main className={`relative overflow-x-hidden xl:overflow-x-auto ${acumin.variable}`}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta charSet="utf-8" />
      </Head>

      <Didomi />
      {GTM_ID && <GTM_Global />}

      <SessionProvider session={session}>
        <SpyComponent>
          <TooltipProvider>
            <Component {...pageProps} />
          </TooltipProvider>
        </SpyComponent>

        {sentryEnabled && <SentryUserManager />}
      </SessionProvider>

      <Toaster position="top-right" />
    </main>
  );
};

const SpyComponent = ({ children }: { children?: ReactNode }) => {
  const session = useSession();
  const router = useRouter();
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      GTM_ID && pageview(url);

      session.data &&
        posthog?.capture("$pageview", {
          distinctId: session.data.user.id,
          adminId: session.data.admin?.adminId,
          user: session.data.user,
          admin: session.data.admin,
        });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (session.data) {
      const userId = session.data.user?.id;

      if (userId !== currentUserId) {
        posthog?.identify(userId, {
          user: session.data.user,
          admin: session.data.admin,
        });
        setCurrentUserId(userId);
      }
    }
  }, [session, currentUserId]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default api.withTRPC(appWithTranslation(App, i18nConfig));
